export const getGallery = () => {
    return { type: 'ASYNC_GET_GALLERY', }
}

export const setUploadedFiles = files => {
    return {
        type: 'SET_UPLOADED_FILES',
        payload: files
    }
}

export const asyncUploadFile = file => {
    return {
        type: 'ASYNC_UPLOAD_FILE',
        payload: file
    }
}

export const setExcludedFile = file => {
    console.log(file)
    return {
        type: 'SET_EXCLUDED_FILE',
        payload: file
    }
}

export const deleteImage = file => {
    return {
        type: 'ASYNC_DELETE_IMAGE',
        payload: file
    }
}