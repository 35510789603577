import React, { useEffect } from 'react';
import {DraggableCore} from 'react-draggable';
import { FaRegImages, FaRegPlusSquare, FaRegMinusSquare } from "react-icons/fa";
import { CircularProgressbar } from 'react-circular-progressbar';
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import ModalGalleryTitle from '../ModalGalleryTitle';
import { updateTitle, publishEditPost, setDeltaPosition, updatePosition, updatePublishStatus } from '../../redux/actions/publishActions';
import { getGallery } from '../../redux/actions/galleryActions';
import { getPost, clearPost } from '../../redux/actions/postsActions';
import Editor from '../Editor';

function Main() {
    const imageTitle = useSelector(state => state.PublishReducer.imageTitle);
    const publishDeltaPosition = useSelector(state => state.PublishReducer.deltaPosition);
    const publishresizePosition = useSelector(state => state.PublishReducer.resizePosition);
    const publishStatus = useSelector(state => state.PublishReducer.publishStatus);
    const uploading = useSelector(state => state.PublishReducer.uploading);
    const uploadProgress = useSelector(state => state.PublishReducer.uploadProgress);

    const post = useSelector(state => state.PostsReducer.post);
    const thumbnail = useSelector(state => state.PostsReducer.thumbnail);
    const deltaPosition = useSelector(state => state.PostsReducer.deltaPosition);
    const resizePosition = useSelector(state => state.PostsReducer.resizePosition);
    const currentPublishStatus = useSelector(state => state.PostsReducer.publishStatus);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    let resize;
    
    if(publishresizePosition.width === 100 && publishresizePosition.height === -1) {
        resize = resizePosition;
    } else {
        resize = `${publishresizePosition.width}% ${publishresizePosition.height === -1 ? 'auto' : publishresizePosition.height + '%' }`;
    }
    function handleDrag(e, ui)  {
        const {x, y} = publishDeltaPosition;
            dispatch(setDeltaPosition({
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            }));
          
            document.getElementById('image-title-edit').style.backgroundPosition = `${x}px ${y}px`
        }
    
      function onStart() {
        document.getElementById("image-title-edit").classList.remove('dragable');
        document.getElementById('image-title-edit').classList.add('grabbing');
        document.getElementById('main-contnt-edit').classList.add('grabbing');
      };
    
      function onStop() {
        document.getElementById("image-title-edit").classList.add('dragable');
        document.getElementById('image-title-edit').classList.remove('grabbing');
        document.getElementById('main-contnt-edit').classList.remove('grabbing');
      };
    
      const dragHandlers = {onStart: onStart, onStop: onStop};

    useEffect(() => {
        dispatch(getPost(location.state.id));
        dispatch(getGallery());
        return () => {
            dispatch(clearPost())
        };
    }, []);

    useEffect(() => {
        if(currentPublishStatus) {
            dispatch(updatePublishStatus(true))
        }
    }, [currentPublishStatus]);

    function _submit(event) {
        event.preventDefault();
        dispatch(publishEditPost(history));
        window.scrollTo(0, 10000);
    }

    function formButton() {
        if (uploading){
        return(
            <CircularProgressbar
            styles={{
                root: { width: 50 },
                path: { stroke: '#0a5e51'}
            }}
            strokeWidth={8}
            value={uploadProgress}
            text={`${uploadProgress}%`}
            />
        )
        }
        return (
            <div className="public-buttons-container">
                <button className="btn" type="submit" onClick={() => dispatch(updatePublishStatus(false))}>{publishStatus ? 'Salvar e despublicar' : 'Salvar'}</button>
                <button className="btn" type="submit" onClick={() => dispatch(updatePublishStatus(true))}>{publishStatus ? 'Atualizar publicação' : 'Publicar'}</button>
            </div>
        )
    }

    function openGalleryTitleModal() {
        document.getElementById('modal-container-gallery-title').style.display = "flex";
        document.getElementById('body').style.overflow = "hidden";
    }

    function handleCenter() {
        document.getElementById('image-title-edit').style.backgroundPosition = `${0}px ${publishDeltaPosition.y}px`;
        dispatch(setDeltaPosition({x: 0, y: publishDeltaPosition.y}));
      }
  
    return (
        <div id="main-contnt-edit"className="main-container">
            <ModalGalleryTitle />
            <div className="main-header">
                <div className='main-gallery-container' onClick={() => openGalleryTitleModal() }>
                    <FaRegImages size={24} color='#FFF' />
                </div>
            </div>
            <form className="blog-form" onSubmit={_submit}>
            <div id="form-header">
                <div className="header-section">
                    <label htmlFor="title">Título</label>
                    <input defaultValue={location.state.title} className="title" type="text" id="title" name="title" onBlur={event => dispatch(updateTitle(event.target.value))} /><br/>
                </div>

                <div className="header-section">
                    <div className="thumbnail-title">
                        <div>  
                            <DraggableCore onDrag={handleDrag} {...dragHandlers}>
                                <div 
                                    id="image-title-edit"
                                    className='image-title dragable' 
                                    style={{ backgroundPosition: deltaPosition, backgroundImage: "url(" + `'${imageTitle === '' ? thumbnail : imageTitle}'` + ")", backgroundSize: resize }}
                                />
                            </DraggableCore>
                            <div className="center-image-button-container">
                                <button id="center-image-button" type="button" style={{display: 'flex'}} onClick={() => handleCenter() }>
                                <p>Centralizar</p>
                                </button>
                            </div>
                        </div>
                
                        <div id="resize-image-container" style={{display: 'flex'}}>
                            <div className="resize-item">
                                <span>Largura: {publishresizePosition.width}%</span>
                                <div className="resize-icons-container">
                                <button className="resize-icon-button" type="button">
                                    <FaRegPlusSquare onClick={() => dispatch(updatePosition({width: publishresizePosition.width+1, height: publishresizePosition.height })) } />
                                </button>

                                <button className="resize-icon-button resize-icon-button-minus" type="button">
                                    <FaRegMinusSquare onClick={() => dispatch(updatePosition({width: publishresizePosition.width-1, height: publishresizePosition.height })) } />
                                </button>
                                </div>
                            </div>

                            <div className="resize-item">
                                <span>Altura: {publishresizePosition.height === -1 ? 'Padrão' : publishresizePosition.height + '%'}</span>
                                <div className="resize-icons-container">
                                    <button className="resize-icon-button" type="button">
                                        <FaRegPlusSquare onClick={() => dispatch(updatePosition(publishresizePosition.height === -1 ? {width: publishresizePosition.width, height: 100} : {width: publishresizePosition.width, height: publishresizePosition.height+1})) } />
                                    </button>

                                    <button className="resize-icon-button resize-icon-button-minus" type="button">
                                        <FaRegMinusSquare onClick={() => dispatch(updatePosition(publishresizePosition.height === -1 ? {width: publishresizePosition.width, height: 100} : {width: publishresizePosition.width, height: publishresizePosition.height-1})) } />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Editor editPost={post}/>
                
            <span id="uploadError">Erro ao fazer o upload! tente novamente!</span>
            
            <div className='button-container'>
                { formButton() }
            </div>
            </form>
        </div>
    );
}

export default Main;