import React, { useEffect, useState } from 'react';
import {DraggableCore} from 'react-draggable';
import { CircularProgressbar } from 'react-circular-progressbar';
import { FaRegImages, FaRegPlusSquare, FaRegMinusSquare } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import ModalGalleryTitle from '../ModalGalleryTitle';
import { updateTitle, updatePost, setDeltaPosition, publishPost, updatePosition, updatePublishStatus, exitPost } from '../../redux/actions/publishActions';
import { getGallery } from '../../redux/actions/galleryActions';
import Editor from '../Editor';
import './index.css';
import 'react-circular-progressbar/dist/styles.css';

function Main() {
  const imageTitle = useSelector(state => state.PublishReducer.imageTitle);
  const resizePosition = useSelector(state => state.PublishReducer.resizePosition);
  const deltaPosition = useSelector(state => state.PublishReducer.deltaPosition);
  const uploading = useSelector(state => state.PublishReducer.uploading);
  const uploadProgress = useSelector(state => state.PublishReducer.uploadProgress);
  const dispatch = useDispatch();
  const history = useHistory();

  function handleDrag(e, ui)  {
    const {x, y} = deltaPosition;
      dispatch(setDeltaPosition({
          x: x + ui.deltaX,
          y: y + ui.deltaY,
      }));
      
      document.getElementById('image-title-publish').style.backgroundPosition = `${x}px ${y}px`
  }

  function onStart() {
    document.getElementById("image-title-publish").classList.remove('dragable');
    document.getElementById('image-title-publish').classList.add('grabbing');
    document.getElementById('main-contnt').classList.add('grabbing');
  };

  function onStop() {
    document.getElementById("image-title-publish").classList.add('dragable');
    document.getElementById('image-title-publish').classList.remove('grabbing');
    document.getElementById('main-contnt').classList.remove('grabbing');
  };

  const dragHandlers = {onStart: onStart, onStop: onStop};

  useEffect(() => {
    dispatch(updatePost(''))
    dispatch(getGallery());
    return () => {
      dispatch(exitPost())
    };
}, []);

  async function _submit(event) {
    event.preventDefault();
    dispatch(publishPost(history));
    window.scrollTo(0, 10000);
  }

  function formButton() {
    if (uploading){
      return(
        <CircularProgressbar
          styles={{
            root: { width: 50 },
            path: { stroke: '#0a5e51'}
          }}
          strokeWidth={8}
          value={uploadProgress}
          text={`${uploadProgress}%`}
        />
      )
    }
    return (
      <div className="public-buttons-container">
        <button className="btn" type="submit" onClick={() => dispatch(updatePublishStatus(false))}>Salvar</button>
        <button className="btn" type="submit" onClick={() => dispatch(updatePublishStatus(true))}>Publicar</button>
      </div>
    )
  }

  function imageTitlePreview() {
    if(imageTitle.length > 2) {
      document.getElementById('image-title-publish').style.backgroundImage = "url(" + `'${imageTitle}'` + ")";
      document.getElementById('resize-image-container').style.display = "flex";
      document.getElementById('center-image-button').style.display = "flex";
      document.getElementById('image-title-publish').classList.add('dragable');
    } else {
      return(<span>Selecione uma imagem na galeria</span>);
    }
  }

  function openGalleryTitleModal() {
    document.getElementById('modal-container-gallery-title').style.display = "flex";
    document.getElementById('body').style.overflow = "hidden";
  }

  function handleCenter() {
    document.getElementById('image-title-publish').style.backgroundPosition = `${0}px ${deltaPosition.y}px`;
    dispatch(setDeltaPosition({x: 0, y: deltaPosition.y}));
  }
  
  return (
      <div id="main-contnt" className="main-container">
        <ModalGalleryTitle />
        <div className="main-header">
          <div className='main-gallery-container' onClick={() => openGalleryTitleModal('post') }>
            <FaRegImages size={24} color='#FFF' />
          </div>
        </div>
        <form className="blog-form" onSubmit={_submit}>
          <div id="form-header">
            <div className="header-section">
              <label htmlFor="title">Título</label>
              <input className="title" type="text" id="title" name="title" onBlur={event => dispatch(updateTitle(event.target.value))} /><br/>
            </div>

            <div className="header-section">
              <div className="thumbnail-title">
                <div>  
                  <DraggableCore onDrag={handleDrag} {...dragHandlers}>
                    <div 
                      id='image-title-publish'
                      className='image-title'
                      style={{backgroundSize:`${resizePosition.width}% ${resizePosition.height === -1 ? 'auto' : resizePosition.height + '%' }`}}
                    >
                      { imageTitlePreview() }
                    </div>
                  </DraggableCore>
                  <div className="center-image-button-container">
                    <button id="center-image-button" type="button" onClick={() => handleCenter() }>
                      <p>Centralizar</p>
                    </button>
                  </div>
                </div>
                
                <div id="resize-image-container">
                  <div className="resize-item">
                    <span>Largura: {resizePosition.width}%</span>
                    <div className="resize-icons-container">
                      <button className="resize-icon-button" type="button">
                        <FaRegPlusSquare onClick={() => dispatch(updatePosition({width: resizePosition.width+1, height: resizePosition.height })) } />
                      </button>

                      <button className="resize-icon-button resize-icon-button-minus" type="button">
                        <FaRegMinusSquare onClick={() => dispatch(updatePosition({width: resizePosition.width-1, height: resizePosition.height })) } />
                      </button>
                    </div>
                  </div>

                  <div className="resize-item">
                    <span>Altura: {resizePosition.height === -1 ? 'Padrão' : resizePosition.height + '%'}</span>
                    <div className="resize-icons-container">
                      <button className="resize-icon-button" type="button">
                        <FaRegPlusSquare onClick={() => dispatch(updatePosition(resizePosition.height === -1 ? {width: resizePosition.width, height: 100} : {width: resizePosition.width, height: resizePosition.height+1})) } />
                      </button>

                      <button className="resize-icon-button resize-icon-button-minus" type="button">
                        <FaRegMinusSquare onClick={() => dispatch(updatePosition(resizePosition.height === -1 ? {width: resizePosition.width, height: 100} : {width: resizePosition.width, height: resizePosition.height-1})) } />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <Editor />
              
          <span id="uploadError">Erro ao fazer o upload! tente novamente!</span>
          
          <div className='button-container'>
            { formButton() }
          </div>
        </form>
      </div>
  );
}

export default Main;