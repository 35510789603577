import { takeLatest, put, call, select, fork, take, cancelled } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import date from 'date-and-time';
import api from '../services/api';

export default function* rootSaga() {
    yield takeLatest('LOGIN', asyncLogin);
    yield takeLatest('PUBLISH_POST', asyncPublishPost);
    yield takeLatest('PUBLISH_EDIT_POST', asyncEditPost);
    yield takeLatest('ASYNC_UPLOAD_FILE', asyncUploadFile);
    yield takeLatest('ASYNC_GET_POSTS', asyncGetPosts);
    yield takeLatest('ASYNC_GET_POST', asyncGetPost);
    yield takeLatest('ASYNC_GET_GALLERY', asyncGetGallery);
    yield takeLatest('ASYNC_GET_COMMENTS', asyncGetComments);
    yield takeLatest('ASYNC_DELETE_POST', asyncDeletePost);
    yield takeLatest('ASYNC_DELETE_IMAGE', asyncDeleteImage);
    yield takeLatest('ASYNC_SEARCH', asyncSearch);
    yield takeLatest('ASYNC_SEND_ANSWER', asyncSendAnswer);
    yield takeLatest('ASYNC_LIKE_COMMENT', asyncLikeComment);
    yield takeLatest('ASYNC_DELETE_COMMENT', asyncDeleteComment);
}

//POSTS SAGAS

function* asyncLogin(action) {
    try {
        const history = action.payload;
        const login = yield select(state => state.AuthReducer.login);
        const password = yield select(state => state.AuthReducer.password);
        const objAuth = { login, password }

        const response = yield call(api.post, '/auth', objAuth);
        const user = response.data.result;
        
        if(user.length === 1){
            document.getElementById("errorLogin").style.display = "none";
            yield put({ type: 'AUTHENTICATE', payload: true });
            history.push("/blog");
        } else {
            yield put({ type: 'AUTHENTICATE', payload: false });
            document.getElementById("errorLogin").style.display = "block";
        }
    } catch (err){
        yield put({ type: 'AUTHENTICATE', payload: false });
        document.getElementById("errorLogin").style.display = "block";
    }
}

function* asyncGetPosts() {
    try {
        const response = yield call(api.get, '/postsAdmin');
        const posts = response.data.result.reverse();
        
        yield put({ type: 'GET_POSTS', payload: posts });

    } catch (err) {

    }
}

function* asyncGetPost(action) {
    try {
        const postID = action.payload;
        const response = yield call(api.get, `/post/${postID}`);
        const post = response.data.result[0];
        post.id = postID;
        
        yield put({ type: 'GET_POST', payload: post });

    } catch (err) {
        
    }
}

function* asyncGetGallery() {
    try {
        const response = yield call(api.get, '/gallery');
        const gallery = response.data.result.reverse();
        
        yield put({ type: 'GET_GALLERY', payload: gallery });

    } catch (err) {
        console.log(err)
    }
}

function* asyncGetComments(action) {
    try {
        const postID = action.payload;
        const response = yield call(api.get, `/comments/${postID}`);
        const comments = response.data.result;

        yield put({ type: 'GET_COMMENTS', payload: comments });

    } catch (err) {
        
    }
}

function* asyncSearch() {
    try {
        const search = yield select(state => state.PostsReducer.search);
        const response = yield call(api.post, '/search', { search });
        const posts = response.data.result.reverse();

        yield put({ type: 'GET_POSTS', payload: posts });

    } catch (err) {
        console.log(err)
    }
}

function* asyncSendAnswer(action) {
    try {
        const commentID = action.payload.commentID;
        const postID = action.payload.postID;
        const answer = yield select(state => state.PostsReducer.answer);

        yield call(api.post, '/answer', { commentID, postID, answer });

        document.getElementById(commentID).style.display = 'none';
        document.getElementById(`${commentID}-span`).innerHTML = 'Resposta enviada!'
        document.getElementById(`${commentID}-span`).style.display = 'block';
        setTimeout(function(){ 
            if(document.getElementById(`${commentID}-span`) !== null)
                document.getElementById(`${commentID}-span`).style.display = "none";
        }, 2000);
        
        yield put({ type: 'ASYNC_GET_COMMENTS', payload: postID });

    } catch (err) {
        console.log(err)
    }
}

function* asyncLikeComment(action) {
    try {
        const postID = yield select(state => state.PostsReducer.id);
        const commentID = action.payload.commentID;
        const show = action.payload.show;
        let newShow;
        if(show === 0) {
            newShow = 1;
        } else { 
            newShow = 0;
        }
        
        yield call(api.post, `/comments/${commentID}`, { newShow });

        document.getElementById(`${commentID}-span`).innerHTML = 'Comentário autorizado!'
        document.getElementById(`${commentID}-span`).style.display = 'block';
        setTimeout(function(){ 
            console.log(`${commentID}-span`)
            if(document.getElementById(`${commentID}-span`) !== null)
                document.getElementById(`${commentID}-span`).style.display = "none";
        }, 2000);
        yield put({ type: 'ASYNC_GET_COMMENTS', payload: postID });

    } catch (err) {
        console.log(err)
    }
}

function* asyncDeleteComment(action) {
    try {
        const postID = yield select(state => state.PostsReducer.id);
        const commentID = action.payload;

        yield call(api.delete, `/comments/${commentID}`);
        
        document.getElementById(`${commentID}-span`).innerHTML = 'Comentário deletado!'
        document.getElementById(`${commentID}-span`).style.display = 'block';
        setTimeout(function(){ 
            if(document.getElementById(`${commentID}-span`) !== null)
                document.getElementById(`${commentID}-span`).style.display = "none";
        }, 2000);
        yield put({ type: 'ASYNC_GET_COMMENTS', payload: postID });

    } catch (err) {
        console.log(err)
    }
}

function* asyncDeletePost() {
    try {
        const postID = yield select(state => state.PostsReducer.id);
        const image = yield select(state => state.PostsReducer.image);
        
        yield call(api.delete, `/post/${postID}`, { data: {image: image} });
        
        yield put({ type: 'FINISH_REMOVE_POST' });
        yield put({ type: 'ASYNC_GET_POSTS' });

        document.getElementById('confirmDeleteMesseger').style.display = "flex";
        setTimeout(function(){ 
            if(document.getElementById("confirmDeleteMesseger") !== null)
                document.getElementById("confirmDeleteMesseger").style.display = "none";
         }, 2000);

    } catch (err) {
        
    }
}

function* asyncDeleteImage(action) {
    try {
        const file = action.payload
        yield call(api.delete, `/gallery/${file.id}`, { data: {image: file.name} });
        
        yield put({ type: 'ASYNC_GET_GALLERY' });

    } catch (err) {
         
    }
}

//PUBLISH SAGAS

function createFileUploaderChannel(data) {
    return eventChannel(emit => {
        const onProgress = ({total, loaded}) => {
            const percentage = parseInt(Math.round((loaded * 100) / total));
            
            emit(percentage)
        }  

        api.post('/gallery', data, { onUploadProgress: onProgress })
        .then(() => { 
            emit('success')
            emit(END) 
        })
        .catch(err => { 
            emit('error')
            emit(END)
        })
        
        const unsubscribe = () => {}
        return unsubscribe
        })
}

function createPostUploaderChannel(data) {
    return eventChannel(emit => {
        const onProgress = ({total, loaded}) => {
            const percentage = parseInt(Math.round((loaded * 100) / total));
            
            emit(percentage)
        }  

        api.post('/posts', data, { onUploadProgress: onProgress })
        .then(() => { emit(END) })
        .catch(err => { 
            emit(new Error(err.message))
            emit(END)
        })
        
        const unsubscribe = () => {}
        return unsubscribe
        })
}

function createEditUploaderChannel(data, postID) {
    return eventChannel(emit => {
        const onProgress = ({total, loaded}) => {
            const percentage = parseInt(Math.round((loaded * 100) / total));
            
            emit(percentage)
        }  

        api.post(`/post/${postID}`, data, { onUploadProgress: onProgress })
        .then(() => { emit(END) })
        .catch(err => { 
            console.log(err)
            emit(new Error(err.message))
            emit(END)
        })
        
        const unsubscribe = () => {}
        return unsubscribe
        })
}

function timeOut() {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(true)
        }, 1000);
    });
}

function* uploadPostProgressWatcher(channel, history, type) {
    while(true){
        try{
            const progress = yield take(channel);

            yield put({ type: 'UPDATE_PROGRESS', payload: progress });

            if(progress === 100) {
                yield call(timeOut);
                yield put({ type: 'FINISH_UPLOAD' });
                history.push("/blog"); // history.push("/blog-admin"); 
                yield put({ type: 'ASYNC_GET_POSTS' })
                if(type === 'publish') {
                    document.getElementById('confirmPublishMesseger').style.display = "flex";
                    setTimeout(function(){ 
                        if(document.getElementById("confirmPublishMesseger") !== null)
                            document.getElementById("confirmPublishMesseger").style.display = "none";
                    }, 2000);
                } else {
                    document.getElementById('confirmEditMesseger').style.display = "flex";
                    setTimeout(function(){ 
                        if(document.getElementById("confirmEditMesseger") !== null)
                            document.getElementById("confirmEditMesseger").style.display = "none";
                    }, 2000);
                }
                
            }
        }
        catch(err){
            document.getElementById("uploadError").style.display = "block";
            setTimeout(function(){ 
                if(document.getElementById("uploadError") !== null)
                    document.getElementById("uploadError").style.display = "none";
             }, 3000);
        }
        finally{
            if(yield cancelled()) {
                console.log('teste')
                channel.close();
            }
        }
    }
}

function* uploadFileProgressWatcher(file, channel) {
    while(true){
        try{
            const uploadedFiles = yield select(state => state.GalleryReducer.uploadedFiles);
            const progress = yield take(channel);
            console.log(typeof progress)
            var newUploadedFiles;
            if(typeof progress === 'number') {
                newUploadedFiles = uploadedFiles.map(uploadedFile => {
                    return file.id === uploadedFile.id ? { ...uploadedFile, progress: progress } : uploadedFile;
                });
            } else {
                if(progress === 'error') {
                    newUploadedFiles = uploadedFiles.map(uploadedFile => {
                        return file.id === uploadedFile.id ? { ...uploadedFile, error: true } : uploadedFile;
                    }); 
                } else {
                    yield put({ type: 'ASYNC_GET_GALLERY' });
                    
                }
            }

            yield put({ type: 'SET_UPLOADED_FILES', payload: newUploadedFiles });
            yield put({ type: 'FINISH_UPLOAD' });
        }
        catch(err){
            yield put({ type: 'FINISH_UPLOAD' });

            document.getElementById("uploadError").style.display = "block";
            setTimeout(function(){ 
                if(document.getElementById("uploadError") !== null)
                    document.getElementById("uploadError").style.display = "none";
             }, 3000);
        }
        finally{
            if(yield cancelled()) {
                channel.close();
            }
        }
    }
}

function* asyncUploadFile(action) {
    try {
        const file = action.payload;
        
        const data = new FormData();

        data.append('file', file.file);

        const uploadChannel = yield call(createFileUploaderChannel, data)
        yield fork(uploadFileProgressWatcher, file, uploadChannel)

    } catch (err){
        yield put({ type: 'FINISH_UPLOAD' });
        document.getElementById("uploadError").style.display = "block";
        setTimeout(function(){ 
            if(document.getElementById("uploadError") !== null)
                document.getElementById("uploadError").style.display = "none";
         }, 3000);
    }
}

function* asyncPublishPost(action) {
    try {
        const history = action.payload;
        const post = yield select(state => state.PublishReducer.post);
        const title = yield select(state => state.PublishReducer.title);
        const publishStatus = yield select(state => state.PublishReducer.publishStatus);
        const imageTitle = yield select(state => state.PublishReducer.imageTitle);
        const deltaPosition = yield select(state => state.PublishReducer.deltaPosition);
        const resizePosition = yield select(state => state.PublishReducer.resizePosition);
        const height = resizePosition.height === -1 ? 'auto' : resizePosition.height + '%';
        const type = 'publish';

        const now = new Date();
        const dateInput = date.format(now, 'YYYY-MM-DD');

        const data = {
            title: title,
            post: post,
            thumbnail: imageTitle,
            deltaPosition: `${deltaPosition.x}px ${deltaPosition.y}px`,
            resizePosition: `${resizePosition.width}% ${height}`,
            publishStatus: publishStatus,
            date: dateInput
        };
        
        const uploadChannel = yield call(createPostUploaderChannel, data)
        yield fork(uploadPostProgressWatcher, uploadChannel, history, type)

    } catch (err){
        yield put({ type: 'ERROR_UPLOAD' });
        document.getElementById("uploadError").style.display = "block";
        setTimeout(function(){ 
            if(document.getElementById("uploadError") !== null)
                document.getElementById("uploadError").style.display = "none";
         }, 3000);
    }
}

function* asyncEditPost(action) {
    try {
        const history = action.payload;
        const postID = yield select(state => state.PostsReducer.id);
        const oldTitle = yield select(state => state.PostsReducer.title);
        const title = yield select(state => state.PublishReducer.title);
        const publishStatus = yield select(state => state.PublishReducer.publishStatus);
        const post = yield select(state => state.PublishReducer.post);
        const imageTitle = yield select(state => state.PublishReducer.imageTitle);
        const thumbnail = yield select(state => state.PostsReducer.thumbnail);
        const publishDeltaPosition = yield select(state => state.PublishReducer.deltaPosition);
        const publishResizePosition = yield select(state => state.PublishReducer.resizePosition);
        const oldDeltaPosition = yield select(state => state.PostsReducer.deltaPosition);
        const oldResizePosition = yield select(state => state.PostsReducer.resizePosition);
        const height = publishResizePosition.height === -1 ? 'auto' : publishResizePosition.height + '%';
        const type = 'edit';
        let delta;
        let resize;

        if(publishDeltaPosition.x === 0 && publishDeltaPosition.y === 0) {
            delta = oldDeltaPosition
        } else {
            delta = `${publishDeltaPosition.x}px ${publishDeltaPosition.y}px`
        }

        if(publishResizePosition.width === 100 && publishResizePosition.height === -1) {
            resize = oldResizePosition
        } else {
            resize = `${publishResizePosition.width}% ${height}`
        }
        const data = {
            title: title === '' ? oldTitle : title,
            post: post,
            thumbnail: imageTitle === '' ? thumbnail : imageTitle,
            deltaPosition: delta,
            resizePosition: resize,
            publishStatus: publishStatus
        };
        
        const uploadChannel = yield call(createEditUploaderChannel, data, postID)
        yield fork(uploadPostProgressWatcher, uploadChannel, history, type)

    } catch (err){
        yield put({ type: 'ERROR_UPLOAD' });
        document.getElementById("uploadError").style.display = "block";
        setTimeout(function(){ 
            if(document.getElementById("uploadError") !== null)
                document.getElementById("uploadError").style.display = "none";
         }, 3000);
    }
}