import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { FaEdit, FaTrashAlt, FaPlus, FaSearch, FaRegImages } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDelete from '../ConfirmDelete';
import { getPosts, postToRemove, setSearch, getSearch } from '../../redux/actions/postsActions';
import './index.css'

function Home() {
    const posts = useSelector(state => state.PostsReducer.posts);
    const search = useSelector(state => state.PostsReducer.search);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPosts());
    }, []);

    function postOptions(index, param) {
        if(param === 'over') {
            document.getElementsByClassName('post-options')[index].style.display = "flex";
        } else {
            document.getElementsByClassName('post-options')[index].style.display = "none";
        }
    }

    function confirmDeletePost(post) {
        dispatch(postToRemove(post));
        document.getElementById('modal-container').style.display = "flex";
    }

    function _search(event) {
        event.preventDefault();
        dispatch(getSearch());
      }

    return (
        <div className="home-container">
            <div id="confirmDeleteMesseger" className='main-confirm-messege'>
                <span>Post deletado!</span>
            </div>
            <div id="confirmPublishMesseger" className='main-confirm-messege'>
                <span>Post publicado!</span>
            </div>
            <div id="confirmEditMesseger" className='main-confirm-messege'>
                <span>Post alterado!</span>
            </div>
            <ConfirmDelete />

            <div id='new-post-button'>
                <form className="search-container" onSubmit={_search}>
                    <input 
                        id="search"  
                        placeholder="Pesquisar"
                        value={search}
                        onChange={ event => dispatch(setSearch(event.target.value)) }
                    />
                    <button className="btn-form-search" type="submit">
                        <FaSearch 
                        style={{ 
                            cursor: 'pointer',
                            height: 21,
                            paddingTop: 7,
                            paddingBottom: 7,
                            paddingLeft: 15,
                            paddingRight: 15,
                            backgroundColor: '#0A5E51',
                            borderTopRightRadius: 4,
                            borderBottomRightRadius: 4
                        }}
                            size={24}
                            color='#FFF'
                        />
                    </button>
                </form>
                <Link to={{pathname: '/galeria'}}>
                    <div className='header-buttons-container'>
                        <FaRegImages size={24} color='#FFF' />
                    </div>
                </Link>
                <Link to={{pathname: '/publicar'}}>
                    <div className='header-buttons-container'>
                        <FaPlus size={24} color='#FFF' />
                    </div>
                </Link>
            </div>

            <ul>
            {posts.map((post, index) => ( 
                <li key={post.id}>
                    <div onMouseOver={() => postOptions(index, 'over')} onMouseLeave={() => postOptions(index, 'leave')} className='thumbnail' style={{backgroundPosition: post.deltaPosition, backgroundImage: "url(" + `'${post.thumbnail}'` + ")", backgroundSize: post.resizePosition}}> 
                        <div className='post-options'>
                            <Link to={{pathname: '/editar', state: post}}>
                                <FaEdit style={{ cursor: 'pointer', padding: 6, backgroundColor: '#0A5E51', borderRadius: 5 }} size={24} color='#FFF' />
                            </Link>
                            <FaTrashAlt style={{ cursor: 'pointer', padding: 6, backgroundColor: '#FF0000', borderRadius: 5 }} size={24} color='#FFF' onClick={() => confirmDeletePost(post)} />
                        </div>
                    </div>
                    <Link to={{pathname: `/post/${post.title.replace(/\s+/g, "_")}`, state: post}}>
                        <div className="post-list-title-container">
                            <p id='post-list-title'>{post.title}</p>
                        </div>
                    </Link>
                </li>
            ))}
            </ul>
        </div>
  );
}

export default Home;