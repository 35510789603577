import React, { useRef, useEffect } from 'react';
import JoditEditor from "jodit-react";
import { useDispatch, useSelector } from 'react-redux';

import { updatePost } from '../../redux/actions/publishActions';

import './index.css';

function Editor({editPost}) {
  const editor = useRef(null);
  const post = useSelector(state => state.PublishReducer.post);
  const dispatch = useDispatch();

  useEffect(() => {
    if(editPost !== undefined)
      dispatch(updatePost(editPost))
  }, [editPost]);

  const buttonOptions = ['bold', 'underline', 'italic', '|', 'ul', 'ol', '|', 'outdent', 'indent', '|', 'font', 'fontsize', 'brush', 'paragraph', '|', 'image', 'video', 'table', 'link', '|', 'align', 'undo', 'redo', '|', 'selectall', 'cut', 'copy', 'paste', '|', 'hr', 'symbol', 'fullsize'];
  const config = {
    controls: {
      font: {
        list: {
          "Josefin Sans, sans-serif" : "Josefin Sans",
          "-apple-system,BlinkMacSystemFont,\'Segoe WPC\',\'Segoe UI\',HelveticaNeue-Light,Ubuntu,\'Droid Sans\',sans-serif": "Fonte do sistema",
        }
      }
    },
    readonly: false,
    buttons: buttonOptions,
    buttonsMD: buttonOptions,
    buttonsSM: buttonOptions,
    buttonsXS: buttonOptions,
    allowResizeX: true,
    allowResizeY: true,
    askBeforePasteHTML: false,
    defaultActionOnPaste: 'text_plain',
    disablePlugins: "xpath,stat",
    height: 500,
    width: '100%',
    enableDragAndDropFileToEditor: true,
    language: 'pt',
    i18n: {
        pt: {
          'Type something': 'Escreva algo',
          'Bold': 'Negrito',
          'Underline': 'Sublinhado',
          'Italic': 'Itálico',
          'Insert Unordered List': 'Lista Desordenada',
          'Insert Ordered List': 'Lista Ordenada',
          'Font size': 'Tamanho da Fonte',
          'Insert Image': 'Imagem',
          'Insert youtube/vimeo video': 'Vídeo',
          'Insert table': 'Tabela',
          'Insert link': 'Link',
          'Undo': 'Desfazer',
          'Redo': 'Refazer',
          'Select all': 'Selecionar Tudo',
          'Cut selection': 'Cortar Seleção',
          'Copy selection': 'Copiar Seleção',
          'Paste from clipboard': 'Colar',
          'Insert Horizontal Line': 'Inserir Linha Horizontal',
          'Insert Special Character': 'Inserir Character Especial',
          'Open editor in fullsize': 'Tela Cheia',
          'Drop image': 'Arraste a imagem',
          'or click': 'ou clique aqui',
          'Insert': 'confirmar',
          'Split vertical': 'Dividir na vertical',
          'Split horizontal': 'Dividir na horizontal',
          'Top': 'Topo',
          'Middle': 'Meio',
          'Bottom': 'Baixo',
          'Insert column before': 'Inserir coluna antes',
          'Insert column after': 'Inserir coluna depois',
          'Insert row above': 'Inserir linha acima',
          'Insert row below': 'Inserir linha abaxio',
          'Delete table': 'Deletar tabela',
          'Delete row': 'Deletar linha',
          'Delete column': 'Deletar coluna',
          'Empty cell': 'Limpar Tabela',
          'Background': 'Fundo',
          'Text': 'Texto',
          'Border': 'Borda',
          'Open in new tab': 'Abrir em nova guia',
          'No follow': 'Abrir em nova guia',
          'Delete': 'Excluir',
          'Edit': 'Editar',
          'Vertical align': 'Alinhamento vertical',
          'Horizontal align': 'Alinhamento horizontal',
          'Left': 'Esquerda',
          'Right': 'Direita',
          'Center': 'Meio',
          'Image properties': 'Propriedades da imagem',
          'Image': 'Imagem',
          'Src': 'Link da imagem',
          'Title': 'Título',
          'Alternative': 'Título Alternativo',
          'Link': 'Criar link',
          'Open link in new tab': 'Abrir link em nova janela',
          'Advanced': 'Avançado',
          'Margins': 'Margens',
          'Styles': 'Estilos',
          'Classes': 'Classes HTML',
          'Border radius': 'Arredondamento das bordas',
          'Align': 'Alinhar',
          'Alternative text': 'Texto alternativo',
          'Font family': 'Família da fonte'
        }
    }
  };
  
  return (
    <JoditEditor
      ref={editor}
      value={post}
      config={config}
      tabIndex={1}
      onBlur={newContent => dispatch(updatePost(newContent))}
    />
  );
}

export default Editor;