const INITIAL_STATE = {
    post: '',
    title: '',
    imageTitle: '',
    publishStatus: false,
    deltaPosition: {x: 0, y: 0},
    resizePosition: {width: 100, height: -1},
    uploading: false,
    uploadProgress: 0,
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'UPDATE_POST':
            return { ...state, post: action.payload }
        case 'SET_IMAGE_TITLE':
            return { ...state, imageTitle: action.payload }
        case 'SET_DELTA_POSITION':
            return { ...state, deltaPosition: action.payload }
        case 'GET_POST':
            return { ...state, imageTitle: '', deltaPosition: {x: 0, y: 0}, resizePosition: {width: 100, height: -1} }
        case 'UPDATE_TITLE':
            return { ...state, title: action.payload }
        case 'PUBLISH_POST':
            return { ...state, uploading: true }
        case 'PUBLISH_EDIT_POST':
            return { ...state, uploading: true }
        case 'UPDATE_PROGRESS':
            return { ...state, uploadProgress: action.payload }
        case 'UPDATE_POSITION':
            return { ...state, resizePosition: action.payload }
        case 'UPDATE_PUBLISH_STATUS':
            return { ...state, publishStatus: action.payload }
        case 'ERROR_UPLOAD':
            return { ...state, uploading: false, uploadProgress: 0 }
        case 'CLEAR_POST':
            return { ...state, publishStatus: false }
        case 'FINISH_UPLOAD':
            return { ...state, uploading: false, uploadProgress: 0, post: '', title: '', imageTitle: '', deltaPosition: {x: 0, y: 0}, resizePosition: {width: 100, height: -1}, uploading: false }
        default:
            return state;
    }
}