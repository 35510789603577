import React, { useEffect } from 'react';
import { FaComment, FaHeart, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

import { getPost, clearPost, setAnswer, getComments, sendAnswer, likeComment, deleteComment  } from '../../redux/actions/postsActions';
import './style.css'

function Post({props}) {
    const post = useSelector(state => state.PostsReducer.post);
    const title = useSelector(state => state.PostsReducer.title);
    const comments = useSelector(state => state.PostsReducer.comments);
    const answer = useSelector(state => state.PostsReducer.answer);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if(location.state) {
            dispatch(getPost(location.state.id));
            dispatch(getComments(location.state.id));
        }
        return (()=> dispatch(clearPost()));
    }, []);

    useEffect(() => {
        if(post !== '') {
            document.getElementById("post").innerHTML = post;
        }
    }, [post]);

    function _comment(commentID) {
        console.log(document.getElementById(commentID).style.display)
        if(document.getElementById(commentID).style.display === 'none' || document.getElementById(commentID).style.display === '') {
            dispatch(setAnswer(''));
            document.getElementById(commentID).style.display = 'flex';
        } else {
            document.getElementById(commentID).style.display = 'none';
        }
    }

    function _sendAnswer(event, commentID, postID) {
        event.preventDefault();
        dispatch(sendAnswer(commentID, postID));
    }

    function likeStyle(show) {
        if(show === 1) {
            return { color: '#dd3838'}
        }

        return {}
    }

    function commentStyle(show) {
        if(show === 1) {
            return { color: '#109783'}
        }

        return {}
    }

    return (
        <div className="post-container">
            <h1>{ title }</h1>
            <div id="post"/>
            {comments.map(comment => (
                <div className='comments-container' key={comment.id}>
                    <p>{comment.comment}</p>
                    <div className='btn-comments-container'>
                        <FaComment style={commentStyle(comment.answer)} className='icon-comment-container comment-icon' onClick={() => _comment(comment.id)} />
                        <FaHeart style={likeStyle(comment.show)} className='icon-comment-container like-icon' onClick={() => dispatch(likeComment(comment.id, comment.show)) } />
                        <FaTrashAlt className='icon-comment-container trash-icon' onClick={() => dispatch(deleteComment(comment.id)) } />
                    </div>
                    <span id={`${comment.id}-span`} className='coment-status'></span>
                    <form id={comment.id} className="answer-container" onSubmit={event => _sendAnswer(event, comment.id, comment.postID)}>
                        <textarea  
                            id="answer"  
                            placeholder="Escreva aqui..."
                            value={answer}
                            onChange={ event => dispatch(setAnswer(event.target.value)) }
                        />
                        <span id='confirm-answer'>Sua resposta foi enviada!</span>
                        <button className="btn-form-answer" type="submit">
                            <span>Enviar</span>
                        </button>
                    </form>
                </div>
            ))}
        </div>
    );
}

export default Post;