import React from 'react';
import { FaRegTimesCircle, FaRegCopy, FaCheck } from "react-icons/fa";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import { setImageTitle } from '../../redux/actions/publishActions';
import './styles.css'

function ModalGalleryTitle() {
    const uploadedFiles = useSelector(state => state.GalleryReducer.uploadedFiles);
    const dispatch = useDispatch();

    function closeModal() {
        document.getElementById('modal-container-gallery-title').style.display = "none";
        document.getElementById('body').style.overflow = "auto";
    }

    function selectImage(file) {
        document.getElementById('modal-container-gallery-title').style.display = "none";
        document.getElementById('body').style.overflow = "auto";
        if(document.getElementById('image-title-publish')) {
            document.getElementById('image-title-publish').style.backgroundPosition = "0px 0px"
        } else {
            document.getElementById('image-title-edit').style.backgroundPosition = "0px 0px"
        } 
        dispatch(setImageTitle(file.url));
    }

    function copyImage() {
        document.getElementById('modal-container-gallery-title').style.display = "none";
        document.getElementById('body').style.overflow = "auto";
    }

    function postOptions(index, param) {
        if(param === 'over') {
            document.getElementsByClassName('post-options')[index].style.display = "flex";
        } else {
            document.getElementsByClassName('post-options')[index].style.display = "none";
        }
    }

    return (
        <div id="modal-container-gallery-title">
            <div className="modal-content-gallery-title">
                <div className="header-modal-gallery-title">
                    <FaRegTimesCircle style={{ cursor: 'pointer', padding: 6, borderRadius: 5 }} size={24} color='#FF0000' onClick={() => closeModal() } />
                </div>
                <ul>
                {uploadedFiles.map((file, index) => {
                    const thumbnail = file.url;
                    return ( 
                    <li key={file.id}>
                        <div className='preview' style={{ backgroundImage: "url(" + `'${thumbnail}'` + ")" }} onMouseOver={() => postOptions(index, 'over')} onMouseLeave={() => postOptions(index, 'leave')}>
                            <div className='post-options'>
                                <CopyToClipboard text={file.url} onCopy={() => copyImage() }>
                                    <FaRegCopy style={{ cursor: 'pointer', padding: 6, backgroundColor: '#FFF', borderRadius: 5 }} size={24} color='#0A5E51' />
                                </CopyToClipboard>
                                <FaCheck style={{ cursor: 'pointer', padding: 6, backgroundColor: '#0A5E51', borderRadius: 5 }} size={24} color='#FFF' onClick={() => selectImage(file) } />
                            </div>
                        </div>
                    </li>
                    )
                })}
                </ul>
            </div>
        </div>
  );
}

export default ModalGalleryTitle;