export const setLogin = login => {
    return {
        type: 'SET_AUTH_LOGIN',
        payload: login
    }
}

export const setPassword = password => {
    return {
        type: 'SET_AUTH_PASSWORD',
        payload: password
    }
}

export const loginWeb = history => {
    return { 
        type: 'LOGIN',
        payload: history
    }
}