export const getPosts = () => {
    return { type: 'ASYNC_GET_POSTS' }
}

export const getPost = postID => {
    return { 
        type: 'ASYNC_GET_POST',
        payload: postID
    }
}

export const getComments = postID => {
    return { 
        type: 'ASYNC_GET_COMMENTS',
        payload: postID
    }
}

export const postToRemove = post => {
    return { 
        type: 'POST_TO_REMOVE',
        payload: post
    }
}

export const finishRemovePost = () => {
    return { type: 'FINISH_REMOVE_POST' }
}

export const deletePost = () => {
    return { type: 'ASYNC_DELETE_POST' }
}

export const setSearch = text => {
    return { 
        type: 'SET_SEARCH',
        payload: text
    }
}

export const getSearch = () => {
    return { type: 'ASYNC_SEARCH' }
}

export const clearPost = () => {
    return { type: 'CLEAR_POST' }
}

export const setAnswer = text => {
    return { 
        type: 'SET_ANSWER',
        payload: text
    }
}

export const sendAnswer = (commentID, postID) => {
    return { 
        type: 'ASYNC_SEND_ANSWER',
        payload: { commentID, postID }
    }
}

export const likeComment = (commentID, show) => {
    return { 
        type: 'ASYNC_LIKE_COMMENT',
        payload: { commentID, show }
    }
}

export const deleteComment = commentID => {
    return {
        type: 'ASYNC_DELETE_COMMENT',
        payload: commentID
    }
}