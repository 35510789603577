import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { finishRemovePost, deletePost } from '../../redux/actions/postsActions';
import './index.css'

function ConfirmDelete() {
    const title = useSelector(state => state.PostsReducer.title);
    const dispatch = useDispatch();

    function onCancel() {
        document.getElementById('modal-container').style.display = "none";
        dispatch(finishRemovePost());
    }

    function onConfirm() {
        document.getElementById('modal-container').style.display = "none";
        dispatch(deletePost());
    }

  return (
        <div id="modal-container">
            <div className="modal-content">
                <p>Tem certeza de que deseja apagar o post "{title}"?</p>
                <div className="button-content-modal">
                    <button className="confirm" type="button" onClick={onConfirm}>Confirmar</button>
                    <button className="cancel" type="button" onClick={onCancel}>Cancelar</button>
                </div>
            </div>
        </div>
  );
}

export default ConfirmDelete;