import React, { useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { CircularProgressbar } from 'react-circular-progressbar';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaRegCopy, FaTrashAlt, FaRegTimesCircle } from "react-icons/fa";
import uuid from 'react-native-uuid';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDeleteImg from '../ConfirmDeleteImg';
import { getGallery, setUploadedFiles, asyncUploadFile, setExcludedFile } from '../../redux/actions/galleryActions';
import './index.css';
import 'react-circular-progressbar/dist/styles.css';

function Main() {
  const uploadedFiles = useSelector(state => state.GalleryReducer.uploadedFiles);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGallery());
}, []);

  function confirmDeleteImage(file) {
    document.getElementById('modal-container-gallery').style.display = "flex";
    dispatch(setExcludedFile(file));
  }

  function uploadImage(files) {
    const _uploadedFiles = files.map(file => ({
      file,
      id: uuid.v1(),
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    dispatch(setUploadedFiles(_uploadedFiles.concat(uploadedFiles)));

    _uploadedFiles.forEach( file => {
      dispatch(asyncUploadFile(file));
    });
  }

  function dragColor(isDragActive, isDragReject) {
    if(isDragReject) {
      return '#FF0000'
    } else if (isDragActive) {
      return '#0A5E51'
    } else {
      return '#DDD'
    }
  }

  function postOptions(index, param) {
    if(param === 'over') {
        document.getElementsByClassName('post-options')[index].style.display = "flex";
    } else {
        document.getElementsByClassName('post-options')[index].style.display = "none";
    }
  }

  function alertCopy(index, param) {
    document.getElementById('confirmCopyMessege').style.display = "flex";
        setTimeout(function(){ 
            if(document.getElementById("confirmCopyMessege") !== null)
                document.getElementById("confirmCopyMessege").style.display = "none";
         }, 2000);
  }

  function fileList() {
    return (
      <ul>
        {uploadedFiles.map((file, index) => {
            const thumbnail = file.url ? file.url : file.preview
            return ( 
              <li key={file.id}>
                <div className='preview' onMouseOver={() => postOptions(index, 'over')} onMouseLeave={() => postOptions(index, 'leave')} style={{backgroundImage: "url(" + `'${thumbnail}'` + ")"}}> 
                  { !!file.uploaded && (
                    <div className='post-options'>
                      <CopyToClipboard text={file.url} onCopy={() => alertCopy() }>
                        <FaRegCopy style={{ cursor: 'pointer', padding: 6, backgroundColor: '#0A5E51', borderRadius: 5 }} size={24} color='#FFF' />
                      </CopyToClipboard>
                      <FaTrashAlt style={{ cursor: 'pointer', padding: 6, backgroundColor: '#FF0000', borderRadius: 5 }} size={24} color='#FFF' onClick={() => confirmDeleteImage(file)} />
                    </div>
                  )}
                  { !file.uploaded && !file.error && (
                    <CircularProgressbar
                      background
                      styles={{
                        root: { width: 50 },
                        path: { stroke: '#0a5e51'}
                      }}
                      strokeWidth={8}
                      value={file.progress}
                      text={`${file.progress}%`}
                    />
                  )}
                  { file.error && (<FaRegTimesCircle style={{ padding: 6, backgroundColor: 'transparent', borderRadius: 5 }} size={50} color='#FF0000'/>)}
                </div>
              </li>
            )
          }
        )
      }
      </ul>
    );
  }
  
  return (
    <div className="gallery-container">
      <div id="confirmCopyMessege" className='main-confirm-messege'>
        <span>Link Copiado!</span>
      </div>
      <ConfirmDeleteImg />
      <div id="header-gallery">
          <Dropzone accept='image/*' onDropAccepted={uploadImage}>
              { ({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                  <div
                    { ...getRootProps()}
                    style = {{ borderColor: dragColor(isDragActive, isDragReject) }}
                    className='dropzone dropzone-gallery'>
                  <input { ...getInputProps() }/>
                      <span>Clique ou arraste imagens aqui</span>
                  </div>
              ) }
          </Dropzone>
      </div>
      <div className="gallery-content">
        { !!uploadedFiles.length && fileList() }          
      </div>
    </div>
  );
}

export default Main;