import { combineReducers } from 'redux';

import AuthReducer from './authReducer';
import PostsReducer from './postsReducer';
import PublishReducer from './publishReducer';
import GalleryReducer from './galleryReducer';

export default combineReducers({
    AuthReducer,
    PostsReducer,
    PublishReducer,
    GalleryReducer
});