export const updatePost = post => {
    return {
        type: 'UPDATE_POST',
        payload: post
    }
}

export const setImageTitle = url => {
    return {
        type: 'SET_IMAGE_TITLE',
        payload: url
    }
}

export const updateTitle = title => {
    return {
        type: 'UPDATE_TITLE',
        payload: title
    }
}

export const publishPost = history => {
    return { 
        type: 'PUBLISH_POST',
        payload: history
    }
}

export const publishEditPost = history => {
    return {
        type: 'PUBLISH_EDIT_POST',
        payload: history
    }
}

export const setDeltaPosition = deltaPosition => {
    return {
        type: 'SET_DELTA_POSITION',
        payload: deltaPosition
    }
}

export const updatePosition = position => {
    return {
        type: 'UPDATE_POSITION',
        payload: position
    }
}

export const updatePublishStatus = status => {
    return {
        type: 'UPDATE_PUBLISH_STATUS',
        payload: status
    }
}

export const exitPost = () => {
    return { type: 'FINISH_UPLOAD' }
}