const INITIAL_STATE = {
    uploadedFiles: [],
    uploading: false,
    uploadProgress: 0,
    excludedFile: {}
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'GET_GALLERY':
            return { ...state, uploadedFiles: action.payload }
        case 'SET_UPLOADED_FILES':
            return { ...state, uploadedFiles: action.payload }
        case 'SET_EXCLUDED_FILE':
            return { ...state, excludedFile: action.payload }
        default:
            return state;
    }
}