import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteImage } from '../../redux/actions/galleryActions';
import './index.css'

function ConfirmDelete({file}) {
    const excludedFile = useSelector(state => state.GalleryReducer.excludedFile);
    const dispatch = useDispatch();

    function onCancel() {
        document.getElementById('modal-container-gallery').style.display = "none";
    }

    function onConfirm() {
        document.getElementById('modal-container-gallery').style.display = "none";
        dispatch(deleteImage(excludedFile));
    }

  return (
        <div id="modal-container-gallery">
            <div className="modal-content-gallery">
                <p>Tem certeza de que deseja apagar a imagem?</p>
                <div className="button-content-modal-gallery">
                    <button className="confirm-gallery" type="button" onClick={onConfirm}>Confirmar</button>
                    <button className="cancel-gallery" type="button" onClick={onCancel}>Cancelar</button>
                </div>
            </div>
        </div>
  );
}

export default ConfirmDelete;