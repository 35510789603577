const INITIAL_STATE = {
    login: '',
    password: '',
    auth: false
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'SET_AUTH_LOGIN':
            return { ...state, login: action.payload }
        case 'SET_AUTH_PASSWORD':
            return { ...state, password: action.payload }
        case 'AUTHENTICATE':
            return { ...state, auth: action.payload }
        default:
            return state;
    }
}
