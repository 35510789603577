import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import './styles.css';
import logo from './drWaisman-logo-white.png';
import { setLogin, setPassword, loginWeb } from '../../redux/actions/authActions';

export default function Login() {
  const login = useSelector(state => state.AuthReducer.login);
  const password = useSelector(state => state.AuthReducer.password);
  const auth = useSelector(state => state.AuthReducer.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  async function _login(event) {
    event.preventDefault();
    dispatch(loginWeb(history));
  }

  function formLogin() {
    if(!auth) {
      return(
        <>
          <img src={logo} alt="logo" />
          <form onSubmit={_login}>

            <label htmlFor="login">LOGIN *</label>
            <input 
              id="login"  
              placeholder="Login"
              value={login}
              onChange={ event => dispatch(setLogin(event.target.value)) }
            />

            <label htmlFor="password">SENHA *</label>
            <input 
              id="password"
              type="password" 
              placeholder="Senha"
              value={password}
              onChange={ event => dispatch(setPassword(event.target.value)) }
            />

            <span id="errorLogin" className="errorMessage">Erro ao autenticar usuário</span>

            <button className="btn" type="submit">Entrar</button>
          </form>
        </>
      )
    }
  }

  useEffect(() => {
    if(auth) {
      history.push("/blog")
    }
  }, [auth]);

  return (
    <div id="login-container" className="content">
        <div className="form-login-container">
            { formLogin() }
        </div>
    </div>
  )
}