import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect  } from "react-router-dom";

import { isAuthenticated } from './services/routesAuth';
import Login from './Components/Login';
import Main from './Components/Main';
import Home from './Components/Home';
import Post from './Components/Post';
import EditPost from './Components/EditPost';
import Gallery from './Components/Gallery';

function PrivateRoute({ children, ...rest }) {
    return (
      <Route { ...rest } render = { ({ location }) => 
        isAuthenticated() ? (
            children
        ) : (
          <Redirect to = {{ pathname: '/', state: { from: location } }} />
          )
        }
      />
    );
  }

function Routes() {
    return (
        <Router>
            <Switch>
                {/*<Route basename="http://162.241.92.122/blog-admin/" exact path="/blog-admin"> */}
                <Route basename="/" exact path="/">
                    <Login />
                </Route>
                <PrivateRoute path="/blog">
                    <Home />
                </PrivateRoute>
                <PrivateRoute path="/editar">
                    <EditPost />
                </PrivateRoute>
                <PrivateRoute path="/publicar">
                    <Main />
                </PrivateRoute>
                <PrivateRoute path="/galeria">
                    <Gallery />
                </PrivateRoute>
                <PrivateRoute path="/post">
                    <Post />
                </PrivateRoute>
            </Switch>
        </Router>
    );
}

export default Routes;