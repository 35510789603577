const INITIAL_STATE = {
    id: 0,
    title: '',
    post: '',
    thumbnail: '',
    deltaPosition: '',
    resizePosition: '',
    publishStatus: false,
    posts: [],
    search: '',
    comments: [],
    answer: ''
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'GET_POSTS':
            return { ...state, posts: action.payload }
        case 'GET_POST':
            return { ...state, id: action.payload.id, title: action.payload.title, post: action.payload.post, thumbnail: action.payload.thumbnail, deltaPosition: action.payload.deltaPosition, resizePosition: action.payload.resizePosition, publishStatus: action.payload.publishStatus }
        case 'CLEAR_POST':
            return { ...state, id: 0, title: '', post: '', thumbnail: '',  deltaPosition: '', resizePosition: '', publishStatus: false }
        case 'POST_TO_REMOVE':
            return { ...state, id: action.payload.id, title: action.payload.title }
        case 'FINISH_REMOVE_POST':
            return { ...state, id: 0, title: '' }
        case 'SET_SEARCH':
            return { ...state, search: action.payload }
        case 'SET_ANSWER':
            return { ...state, answer: action.payload }
        case 'GET_COMMENTS':
            return { ...state, comments: action.payload }
        default:
            return state;
    }
}